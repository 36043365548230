import type { CSSProperties, ReactNode, ComponentPropsWithoutRef } from "react";
import type { IconName } from "@blueprintjs/icons";
import { UiSchema } from "@rjsf/utils";
import type { ComponentImportRegistry } from "./componentImports";
import { type JSONSchema } from "json-schema-to-ts";
import { Permission } from "domain/application/permissions/Permissions";
import { isPermitted } from "./jm";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16";

export type ComponentName = keyof ComponentImportRegistry;

// We won't require all uiprops to be set either at init or in the state.
// The clients of the props can provide defaults if they don't exist.
export type UIProps = Partial<{
  label: ReactNode;
  icon: IconName;

  resizable: boolean;

  dynamicSizing: boolean;

  maximised: boolean;
  minimised: boolean;
  minimisable: boolean;
  maximisable: boolean;

  minWidth: number;
  minHeight: number;
  width: number;
  height: number;
  restoredWidth: number;
  restoredHeight: number;

  xPosition: number;
  yPosition: number;
  xRestoredPosition: number;
  yRestoredPosition: number;
  centered: boolean;

  isActive: boolean;
  zIndex: CSSProperties["zIndex"];
  tools: ReactNode;

  mobileStackIndex: number; // Use if we want to insert the component into a specific place in the stack
}>;

export type ComponentProps<T extends ComponentName> = ComponentPropsWithoutRef<
  ComponentImportRegistry[T]
>;

export type ComponentState<T extends ComponentName> = {
  componentType: T;
  componentProps: ComponentProps<T>;
  uiProps: UIProps;
};

export const isValidComponentType = (
  key: string
): key is keyof ComponentImportRegistry => {
  return Object.prototype.hasOwnProperty.call(componentOptions, key);
};

export type Group = "sales" | "production" | "inventory" | "admin" | "other";

export function isGroup(value: any): value is Group {
  return ["sales", "production", "inventory", "admin", "other"].includes(value);
}

export const componentGroups: Record<
  Group,
  { name: string; icon?: BlueprintIcons_16Id }
> = {
  sales: {
    name: "Sales",
    icon: "dollar",
  },
  production: {
    name: "Production",
  },
  inventory: {
    name: "Inventory",
    icon: "box",
  },
  admin: {
    name: "Administration",
    icon: "cog",
  },
  other: {
    name: "Other",
    icon: "menu",
  },
};

export type ComponentOptionsRegistryEntry = {
  defaultUiOptions: Partial<UIProps>;
  component?: ComponentName;
  defaultComponentProps?: ComponentProps<ComponentName>;
  config?: {
    schema?: JSONSchema;
    uiSchema?: UiSchema;
  };
  requiredPermissions?: Permission[];
  showInLauncher?: boolean;
  canOpen?: () => Promise<boolean> | boolean;
  group?: Group;
};

// IMPORTANT: Do not import this anywhere except into ProxyComponent,
// as it introduces circular deps and breaks HMR
export const componentOptions: Record<
  ComponentName,
  ComponentOptionsRegistryEntry
> = {
  JobWizard: {
    defaultUiOptions: {
      label: "New Job",
      maximised: false,
      width: 700,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  JobPhotoUpload: {
    defaultUiOptions: {
      label: "Job Photo Upload",
      minimisable: false,
      maximisable: false,
      maximised: false,
      width: 500,
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Upload_Request_Url),
  },
  ProductImport: {
    defaultUiOptions: {
      label: "Product Import",
      maximised: true,
    },
    showInLauncher: false,
  },
  ProductManager: {
    defaultUiOptions: {
      label: "Products",
      maximised: true,
    },
    showInLauncher: true,
    group: "inventory",
    //requiredPermissions: [Permission.PRODUCTS_IMPORT],
  },
  CostingEditor: {
    defaultUiOptions: {
      label: "Costing Editor",
      maximised: true,
    },
    showInLauncher: false,
  },
  Branches: {
    defaultUiOptions: {
      label: "Branches",
      icon: "office",
    },
    group: "admin",
    showInLauncher: true,
  },
  SchemaVersionEditor: {
    defaultUiOptions: {
      label: "Data Template Version Editor",
      maximised: true,
    },
    showInLauncher: false,
  },
  TimeDashboard: {
    defaultUiOptions: {
      label: "Time Dashboard",
    },
    showInLauncher: true,
  },
  AutoScreen: {
    defaultUiOptions: {
      label: "Auto Screen",
      maximised: true,
    },
    showInLauncher: true,
    canOpen: () => false,
  },
  SupplierEditor: {
    defaultUiOptions: {
      label: "Supplier",
      width: 500,
      height: 500,
      resizable: true,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  SupplierWizard: {
    defaultUiOptions: {
      label: "New Supplier",
      width: 800,
      height: 650,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  HousingCompanyEditor: {
    defaultUiOptions: {
      label: "Housing Company",
      width: 400,
      height: 625,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  HousingCompanyWizard: {
    defaultUiOptions: {
      label: "New Housing Company",
      width: 400,
      height: 235,
      minHeight: 0,
      minWidth: 0,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  QuoteRequestEditor: {
    defaultUiOptions: {
      label: "Quote Request",
      maximised: true,
    },
    showInLauncher: true,
    group: "sales",
  },
  QuoteEditor: {
    defaultUiOptions: {
      label: "Quote",
      maximised: true,
    },
    showInLauncher: true,
    group: "sales",
  },
  JobEmailEditor: {
    defaultUiOptions: {
      label: "Email",
      maximised: true,
    },
    showInLauncher: false,
  },
  QueryBrowser: {
    defaultUiOptions: {
      label: "System Manager",
      maximised: true,
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Create_User),
  },
  DocumentTemplateEditor: {
    defaultUiOptions: {
      label: "Document Editor",
      maximised: true,
    },
    showInLauncher: false,
  },
  ApiBrowser: {
    defaultUiOptions: {
      label: "API Browser",
      maximised: true,
    },
    showInLauncher: false,
    canOpen: () => true,
  },
};
